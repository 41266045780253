<style>

</style>

<template>

       <vue-country-code
            @onSelect="onSelect"
            :defaultCountry="defaultCountry"
        >
       </vue-country-code>

</template>

<script>
import VueCountryCode from "vue-country-code";
    
    export default {
        name: 'AreaCode',
        components: {
            VueCountryCode
        },
        props: ['defaultCountry'],
        methods: {
            onSelect({name, iso2, dialCode}){
             
                this.$emit('setAreaCode', dialCode);
                this.$emit('setIsoCode', iso2);
            }
        },
        mounted() {
            
        }
        
    }

</script>